export const Game = async (gameName) => {
  try {
    const gameModule = await import(`./${gameName}.js`);
    return gameModule[gameName];
  } catch (e) {
    const gameModule = await import('./Slot');
    return gameModule.Slot;
  }
};

export { Wheel } from '@/games/Wheel';
